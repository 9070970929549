import PropTypes from 'prop-types';
import Text from '../atoms/Text';

const Options = ({
    setQuestions,
    id,
    question,
    active,
    title,
    ...property
}) => {
    const handleOptions = () => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((prevQuestion) => {
                if (question.id === prevQuestion.id) {
                    prevQuestion.options.map((option) => {
                        if (prevQuestion.isMulti) {
                            if (!option.selected) {
                                Object.assign(option, {
                                    selected: option.id === id
                                });
                            } else if (option.selected) {
                                Object.assign(option, {
                                    selected: option.id !== id
                                });
                            }
                        } else {
                            Object.assign(option, {
                                selected: option.id === id
                            });
                        }
                    });
                }
                return prevQuestion;
            })
        );
    };
    return (
        <div
            className={`w-full cursor-pointer hover:border-primary-800 hover:text-primary-800 ${
                active
                    ? 'border-primary-900 text-primary-900'
                    : 'border-neutral-300 text-neutral-500'
            }   border text-center px-4 md:px-8 rounded-md bg-white py-4 ${
                property.className
            }`}
            onClick={handleOptions}
        >
            <Text variant="bodySmall" className={'text-sm md:text-base'}>{title}</Text>
        </div>
    );
};

export default Options;

Options.propTypes = {
    className: PropTypes.string
};
