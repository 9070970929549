import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import OnboardingTemplate from 'templates/OnboardingTemplate';
import { event } from '@/services/gtag.service';
import updateLastSeenDate from '@/lib/update-last-seen';

const Index = ({ records }) => {
    const { status, data } = useSession();
    const [user, setUser] = useState(null);

    const router = useRouter();
    useEffect(() => {
        if (status === 'unauthenticated') {
            router.replace('/auth/signin');
        }
        const fetchUserInfo = async () => {
            const resp = await fetch('/api/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: data?.token?.email
                })
            }).then((data) => data.json());

            setUser(resp.user);
            if (resp.status && resp.user.onboarding !== 'false') {
                router.replace('/dashboard', undefined, { shallow: true });
            } else if (
                resp.status &&
                resp.user.onboarding === 'false' &&
                data.token.stakeholderType !== 'Climate Entrepreneur'
            ) {
                router.replace('/dashboard', undefined, { shallow: true });
            }
        };
        fetchUserInfo();
    }, [status]);

    const onboardingHandler = async (selectedAnswers, onboarding = 'true') => {
        const resp = await fetch('/api/onboarding/climate-entrepreneur', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: data.token.email,
                questions: selectedAnswers,
                stakeholderType: data.token.stakeholderType,
                onboarding
            })
        }).then((data) => data.json());

        if (resp.onboarding) {
            router.replace('/dashboard');
        } else {
            router.replace('/');
        }
    };

    const getComponent = () => {
        if (
            user?.onboarding === 'false' &&
            data.token.stakeholderType === 'Climate Entrepreneur'
        ) {
            return (
                <OnboardingTemplate
                    username={data.token.name}
                    email={data.token.email}
                    id={data.token.id}
                    onboardingHandler={onboardingHandler}
                    buttonHandler={() => {
                        event(
                            {
                                action: 'logout'
                            },
                            {
                                'user-id': data.token.email,
                                'page-name': 'onboarding-page',
                                'btn-clicked': 'yes'
                            }
                        );
                        updateLastSeenDate(data.token.id);
                        signOut({
                            callbackUrl: '/auth/signin',
                            redirect: false
                        });
                    }}
                />
            );
        }
    };

    return <>{status === 'authenticated' && <>{getComponent()}</>}</>;
};

export default Index;
