import { useSession } from 'next-auth/react';
import { useRef, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper } from 'swiper/react';
import Button from '../atoms/Button';
import IcomoonIcon from '../atoms/IcomoonIcon';
import Text from '../atoms/Text';
import { event } from '../services/gtag.service';

export default function OnboardingCaurosel({
    handleSave,
    handleSkip,
    questions,
    isLoading,
    children
}) {
    const { data } = useSession();
    const sliderRef = useRef(null);
    const [isEnd, setIsEnd] = useState(false);
    const handlePrev = () => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        setIsEnd(sliderRef.current.swiper.isEnd);
    };

    const handleNext = () => {
        event(
            {
                action: 'onboarding-next-btn-clicked'
            },
            { 'user-id': data.token.email }
        );
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        setIsEnd(sliderRef.current.swiper.isEnd);
    };
    return (
        <>
            {isEnd ? (
                <div
                    onClick={handlePrev}
                    className="flex cursor-pointer space-x-2 items-center absolute z-50 left-5 md:left-10 top-[7.3rem] lg:left-28 md:top-36"
                >
                    <IcomoonIcon
                        icon={'arrow-left'}
                        color={'#00CC7F'}
                        size={'18'}
                    />
                    <Text
                        variant="bodySmall"
                        className="text-primary-900"
                        fontWeight="font-semibold"
                    >
                        Back
                    </Text>
                </div>
            ) : (
                ''
            )}
            <Swiper
                pagination={true}
                modules={[Pagination]}
                className={'sliderWrapper'}
                preventInteractionOnTransition={true}
                allowTouchMove={false}
                onSlideChange={(e) => {
                    setIsEnd(e.isEnd);
                }}
                ref={sliderRef}
                breakpoints={{
                    100: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                }}
            >
                {children}
            </Swiper>
            <div className="flex space-x-6 md:mt-10 mt-5 w-full justify-center">
                <Button
                    style="borderLess"
                    label="Skip"
                    size="small"
                    onClick={() => {
                        handleSkip();
                        event(
                            {
                                action: `onboarding-skip-q${
                                    sliderRef.current?.swiper.activeIndex + 1
                                }-btn-clicked`
                            },
                            { 'user-id': data.token.email }
                        );
                    }}
                />
                {isEnd ? (
                    <Button
                        label="Save"
                        size="small"
                        onClick={handleSave}
                        disabled={
                            isLoading
                                ? true
                                : !questions[
                                      sliderRef.current?.swiper.activeIndex
                                  ]?.options.some((option) => option.selected)
                        }
                    />
                ) : (
                    <Button
                        label="Next"
                        size="small"
                        onClick={handleNext}
                        disabled={
                            !questions[
                                sliderRef.current?.swiper.activeIndex
                            ]?.options.some((option) => option.selected)
                        }
                    />
                )}
            </div>
        </>
    );
}
