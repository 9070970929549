import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import useWindowFocus from '../hooks/useWindowFocus';
import updateLastSeenDate from '../lib/update-last-seen';
import Navbar from '../molecules/Navbar';
import OnboardingCaurosel from '../molecules/OnboardingCaurosel';
import Questions from '../organisms/Questions';
import { event } from '../services/gtag.service';

const OnboardingTemplate = ({
    username,
    buttonHandler,
    email,
    id,
    stakeholderType,
    onboardingHandler,
    ...property
}) => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [questions, setQuestions] = useState([
        {
            id: 1,
            fieldName: 'Climate Journey Stage',
            name: 'How clear are you about your capital raising going forward?',
            active: true,
            isMulti: false,
            options: [
                {
                    id: 1,
                    title: 'No, I am still exploring',
                    selected: false
                },
                {
                    id: 2,
                    title: 'I have some idea, but would like to know more',
                    selected: false
                },
                {
                    id: 3,
                    title: 'I am certain of what I am looking for',
                    selected: false
                }
            ]
        },
        {
            id: 2,
            name: 'What challenges are you facing with respect to your start-up journey',
            active: false,
            isMulti: true,
            fieldName: 'Challenge',
            options: [
                {
                    id: 4,
                    title: 'Appropriate sources/outlets of funding',
                    selected: false
                },
                {
                    id: 5,
                    title: 'Finding the right talent',
                    selected: false
                },
                {
                    id: 6,
                    title: 'Navigating technology',
                    selected: false
                },
                {
                    id: 7,
                    title: 'Requiring business perspective',
                    selected: false
                }
            ]
        }
    ]);

    const isInFocus = useWindowFocus();
    useEffect(() => {
        if (!isInFocus) {
            updateLastSeenDate(id);
        }
    }, [isInFocus]);

    const handleSave = async () => {
        setIsLoading(true);

        const selectedAnswers = [];
        questions.map((question, index) => {
            const selectedOptions = question.options.filter(
                (option) => option.selected === true
            );
            selectedAnswers.push({
                fieldName: question.fieldName,
                options: selectedOptions
            });
            event(
                {
                    action: 'onboarding-submit-btn-clicked'
                },
                {
                    'user-id': email,
                    [`onboarding-q${index + 1}-attempted`]: question.fieldName,
                    [`onboarding-q${index + 1}-response`]: selectedOptions
                        .map((option) => option.title)
                        .join(', ')
                }
            );
            return question;
        });

        await onboardingHandler(selectedAnswers);
        setIsLoading(false);
    };

    const handleSkip = async () => {
        router.replace('/dashboard', undefined, { shallow: true });
        await onboardingHandler([]);
    };

    return (
        <div className={`w-full ${property.className}`}>
            <Navbar
                shadow="shadow-sm md:shadow-md"
                logo="/images/climateXcapital.png"
                logoAlt="logo"
                logoWidth="88"
                cardPadding="py-2 px-4 md:py-4 md:px-8"
                buttonLabel="Sign Out"
                buttonStyle="ghost"
                extraFeaturs
                navItemsSpace="space-x-6"
                username={username}
                buttonHandler={buttonHandler}
            />
            <OnboardingCaurosel
                handleSave={handleSave}
                handleSkip={handleSkip}
                isLoading={isLoading}
                questions={questions}
                className="w-full"
            >
                {questions.map((que, key) => (
                    <SwiperSlide key={key}>
                        <Questions
                            question={que}
                            setQuestions={setQuestions}
                            handleSkip={handleSkip}
                        />
                    </SwiperSlide>
                ))}
            </OnboardingCaurosel>
        </div>
    );
};

export default OnboardingTemplate;

OnboardingTemplate.propTypes = {};
