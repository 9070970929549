import PropTypes from 'prop-types';

import Text from '../atoms/Text';
import Options from '../molecules/Options';

const Questions = ({ question, setQuestions, ...property }) => (
    <div className={`flex flex-col items-center h-full mt-20 lg:mt-0 justify-start w-full ${property.className}`}>
        <div className="lg:w-1/2 flex flex-col items-center lg:pt-16">
            <div className="mb-8 text-center">
                <Text
                    fontSize="text-base md:text-2xl"
                    className="text-neutral-800 md:mt-5 lg:mt-10 md:mb-2 text-center"
                    fontWeight='font-medium'
                >
                    {question.name}
                </Text>
                {question.isMulti && (
                    <Text
                        variant="bodySmall"
                        className={'text-neutral-500 text-center'}
                    >
                        &quot;Choose as many as applicable&quot;
                    </Text>
                )}
            </div>
            <div className="w-full lg:w-1/2 xl:w-3/5 flex flex-col space-y-6">
                {question.options.map(({ id, title, selected, key }) => (
                    <Options
                        id={id}
                        key={key}
                        title={title}
                        active={selected}
                        setQuestions={setQuestions}
                        question={question}
                    />
                ))}
            </div>
        </div>
    </div>
);

Questions.propTypes = {
    className: PropTypes.string
};

export default Questions;
